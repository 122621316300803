import React from 'react';
import { Link } from 'react-router-dom';
import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { SiGmail } from 'react-icons/si';
import { FaLinkedin } from 'react-icons/fa';
import Logo from '../assets/leaf.svg';
const Navbar = () => {
  return (
    <Popover className="relative bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1 flex-row">
            <Link className='flex-row' to="/">
              <img className="h-8 w-auto sm:h-10" src={Logo} alt="" />
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden md:flex space-x-10">
            <Link
              className="text-base font-medium text-gray-500 hover:text-gray-900"
              to="/"
            >
              Home
            </Link>

            <a
              className="text-base font-medium text-gray-500 hover:text-gray-900"
              href="https://jwds.me/jawadshaissah.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Resume
            </a>

            <Link
              className="text-base font-medium text-gray-500 hover:text-gray-900"
              to="/About"
            >
              About
            </Link>
          </Popover.Group>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">

            <Link
              className="text-base font-medium text-gray-500 hover:text-gray-900 "
              target="_blank"
              to="/Playground"
              rel="noreferrer"
            >
              PLAYGROUND
            </Link>
          </div>
        </div>
      </div>
      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center flex-row justify-between">
                <div className="flex-row	">
                  <Link to="/" >
                    <img className="h-8 w-auto sm:h-10" src={Logo} alt="" />
                  </Link>
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
            </div>
            <div className="mt-6">
              <div className="py-6 px-5 space-y-6">
                <div className="grid grid-cols-1 gap-y-4 gap-x-8 place-content-center  ">
                  <Link
                    to="/"
                    className="text-center font-medium text-gray-900 hover:text-gray-700"
                  >
                    Home
                  </Link>
                  <a
                    href="https://jwds.me/jawadshaissah.pdf"
                    target="_blank"
                    rel="noreferrer"
                    className="text-center font-medium text-gray-900 hover:text-gray-700"

                  >
                    Resume
                  </a>
                  <Link
                    to="/About"
                    className="text-center font-medium text-gray-900 hover:text-gray-700"
                  >
                    About
                  </Link>
                  <div className="flex align-center justify-center ">
                    <a
                      className="text-xl m-1 p-1 sm:m-2 sm:p-2 text-indigo-500 hover:bg-indigo-500 rounded-full hover:text-white transition-colors duration-300"
                      href="https://www.linkedin.com/in/jawad-shaissah/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaLinkedin />
                      <span className="sr-only">Linkedin</span>
                    </a>
                    <a
                      className="text-xl mt-1 p-1 sm:m-2 sm:p-2 text-red-500 hover:bg-red-500 rounded-full hover:text-white transition-colors duration-300"
                      href="mailto:jawadshaissah@gmail.com"
                    >
                      <SiGmail />
                      <span className="sr-only">Email</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
export default Navbar;
