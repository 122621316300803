

import komi from '../assets/komi.jpg';
import Typical from 'react-typical'

export default function About() {
  return (
    <div className=" bg-white w-full  flex flex-col justify-center mt-10 ">
      <div className=" bg-white-500 items-center grid md:grid-cols-2 max-w-[1240px] m-auto  center  justify-center mt-2">
        <div className=" bg-white-500 flex flex-col justify-center items-center md:items-start w-full px-8 mx-auto">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Hey there, I'm Jawad!
          </h2>
          <p className="mt-4 text-gray-500">
            <p>
              <Typical
                steps={[
                  'I am a ',
                  2000,
                  'I am a Software Developer',
                  2000,
                  'I am a Web developer',
                  2000,
                  'I am a Mobile developer',
                  2000,
                  'I am a UX Designer',
                  2000,
                ]}
                loop={1}
                wrapper="b"
              />
            </p>
            I am passionate about web, mobile platforms and translating code
            into user-friendly apps. I have admired how design is one of the
            most important factors in order to make a good product. I love
            learning new technologies and techniques that would enhance my
            skills. My passion for software development is what drives me to
            pursue a career in this and helps me enjoy every part of this
            journey.
          </p>
          <p className="mt-4 text-gray-500">~Jawad</p>
          <a
            href="mailto:jawadshaissah@gmail.com"
            className="mt-4 text-sky-500 "
          >
            jawadshaissah@gmail.com
          </a>
        </div>
        <div className="md:items-end justify-center items-center mx-auto ">
          <img
            className="max-w-full h-90 rounded-lg w-80 mt-2 "
            src={komi}
            alt="me"
          />
        </div>

      </div>
      <br />

      <h2 className="text-2xl font-bold text-center tracking-tight text-gray-600 sm:text-2xl mt-9">
        Here are some things that i enjoy and inspire me.
      </h2>
      <section className=" max-w-7xl mx-auto px-4 sm:px-6 lg:px-4 mb-12 mt-9 ">
        <article>
          <section className=" mt-11 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-x-10 gap-y-10">

            <iframe
              className=" w-full h-60 rounded-xl drop-shadow-2xl"
              src="https://www.youtube.com/embed/KxGRhd_iWuE"
              allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"
              title='NEVER GIVE UP'
            ></iframe>



            <iframe
              className="w-full h-60 rounded-xl drop-shadow-2xl "
              src="https://www.youtube.com/embed/Y3Rs1z7it5M"
              allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"
              title='indie dev'
            ></iframe>
            <iframe
              className="w-full h-60 rounded-xl drop-shadow-2xl "
              src="https://www.youtube.com/embed/_7OFQntGLmI"
              allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"
              title='norway'
            ></iframe>

          </section>
          <section className="mt-11 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-x-10 gap-y-12 ">
            <iframe
              className="w-full h-60 rounded-xl  drop-shadow-2xl "
              src="https://www.youtube.com/embed/xuCn8ux2gbs"
              allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"
              title='egg'
            ></iframe>
            <iframe
              className="w-full h-60 rounded-xl drop-shadow-2xl "
              src="https://www.youtube.com/embed/5rV9RDS1AzM"
              allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"
              title='remembrance'
            ></iframe>
            <iframe
              className="w-full h-60 rounded-xl drop-shadow-2xl "
              src="https://www.youtube.com/embed/OZRYzH0Q0pU"
              allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"
              title='men i trust'
            ></iframe>
          </section>
        </article>
      </section>

    </div>
  );
}
