import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Card from './pages/Card';
import About from './pages/About';
import Playground from './pages/Playground';
function App() {
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route path="/" exact component={Card} />        
        <Route path="/about" component={About} />
        <Route path="/Playground" component={Playground} />
      </Switch>
    </Router>
  );
}

export default App;
