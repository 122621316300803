import { FaGithub } from 'react-icons/fa';
import { FaLinkedin } from 'react-icons/fa';
import { SiGmail } from 'react-icons/si';
import Typical from 'react-typical'

import pic from '../assets/pic3.png';

export default function Card() {
  return (
    <div className="w-full">
      <div className="flex flex-col justify-center max-w-xs my-8 mx-auto  shadow-xl rounded-xl p-5 bg-neutral-900">
        <div className="">
          <img
            className="w-40 mx-auto shadow-xl rounded-full"
            src={pic}
            alt="profil pic"
          />
        </div>
        <div className="text-center mt-5">
          <p className="text-2xl sm:text-2xl font-semibold text-gray-50">
            Jawad Shaissah
          </p>

          <Typical
            className="text-lg sm:text-lg text-gray-400 pt-2 pb-4 px-5 w-58 font-mono "
            steps={['Software Developer', 2000, 'Web developer', 2000, 'Mobile developer', 2000, 'UX Designer', 2000]}
            loop={Infinity}
            wrapper="p"
          />

          <div className="flex align-center justify-center mt-4">
            <a
              className="text-xl m-1 p-1 sm:m-2 sm:p-2 text-gray-200 hover:bg-gray-500 rounded-full hover:text-white transition-colors duration-300 "
              target="_blank"
              href="https://github.com/Huntsama"
              rel="noreferrer"
            >
              <FaGithub />
              <span className="sr-only">Github</span>
            </a>
            <a
              className="text-xl m-1 p-1 sm:m-2 sm:p-2 text-indigo-500 hover:bg-indigo-500 rounded-full hover:text-white transition-colors duration-300"
              target="_blank"
              href="https://www.linkedin.com/in/jawad-shaissah/"
              rel="noreferrer"
            >
              <FaLinkedin />
              <span className="sr-only">Linkedin</span>
            </a>
            <a
              className="text-xl mt-1 p-1 sm:m-2 sm:p-2 text-red-500 hover:bg-red-500 rounded-full hover:text-white transition-colors duration-300"
              href="mailto:jawadshaissah@gmail.com"
            >
              <SiGmail />
              <span className="sr-only">Email</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
